exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-fail-jsx": () => import("./../../../src/pages/fail.jsx" /* webpackChunkName: "component---src-pages-fail-jsx" */),
  "component---src-pages-frischknecht-jsx": () => import("./../../../src/pages/frischknecht.jsx" /* webpackChunkName: "component---src-pages-frischknecht-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../../../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-paintherapy-jsx": () => import("./../../../src/pages/paintherapy.jsx" /* webpackChunkName: "component---src-pages-paintherapy-jsx" */),
  "component---src-pages-prices-jsx": () => import("./../../../src/pages/prices.jsx" /* webpackChunkName: "component---src-pages-prices-jsx" */),
  "component---src-pages-privacy-jsx": () => import("./../../../src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-sakusana-jsx": () => import("./../../../src/pages/sakusana.jsx" /* webpackChunkName: "component---src-pages-sakusana-jsx" */),
  "component---src-pages-success-jsx": () => import("./../../../src/pages/success.jsx" /* webpackChunkName: "component---src-pages-success-jsx" */)
}

